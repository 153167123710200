.marquee-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.7vw;
}

.marquee-wrapper .container {
  overflow: hidden;
}
.marquee-inner span {
  float: left;
  width: 50%;
}
.marquee-wrapper .marquee-block {
  --total-marquee-items: 6;
  height: 6.8vw;
  width: 100vw;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}
.marquee-inner {
  display: block;
  width: 200%;
  position: absolute;
}

.marquee-inner.to-left {
  animation: marqueeLeft 25s linear infinite;
}

.marquee-item {
  height: auto;
  display: inline-block;
  margin: 0 0.69vw;
  float: left;
  transition: all 0.2s ease-out;
  background: #00cc00;
}
@keyframes marqueeLeft {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

.chip {
  margin: 0.7vw 0.35vw;
  display: inline-flex;
  padding: 0.7vw 2.7vw;
  justify-content: center;
  align-items: center;
  gap: 0.7vw;
  border-radius: 7vw;
  border: 0.3vw solid #1c1b1f;
  color: #fff;
  font-family: Inter;
  font-size: 2.7vw;
  font-style: normal;
  font-weight: 600;
  line-height: 3.9vw; /* 140% */
}
.chip.blue {
  border-radius: 7vw;
  background: linear-gradient(#000000, #000000) padding-box,
    linear-gradient(to left, #1d6ff2, #00000000) border-box;
  border: 0.3vw solid transparent;
}
.chip.red {
  border-radius: 7vw;
  background: linear-gradient(#000000, #000000) padding-box,
    linear-gradient(to left, #ff543e, #00000000) border-box;
  border: 0.3vw solid transparent;
}
.chip.yellow {
  border-radius: 7vw;
  background: linear-gradient(#000000, #000000) padding-box,
    linear-gradient(to left, #f6b94c, #00000000) border-box;
  border: 0.3vw solid transparent;
}
.redGradient {
  border: none;
  margin: 0.7vw 0.35vw;
  display: inline-flex;
  padding: 0.7vw 2.7vw;
  justify-content: center;
  align-items: center;
  gap: 0.7vw;
  border-radius: 7vw;
  border: 0.3vw solid #1c1b1f;
  color: #fff;
  font-family: Inter;
  font-size: 2.7vw;
  font-style: normal;
  font-weight: 600;
  line-height: 3.9vw; /* 140% */
  background: rgb(33, 17, 0);
  background: linear-gradient(
    45deg,
    rgba(33, 17, 0, 1) 14%,
    rgba(255, 84, 62, 1) 99%
  );
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes swap {
  0%,
  50% {
    transform: translateX(0%);
  }
  50.01%,
  100% {
    transform: translateX(-100%);
  }
}
