.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal-content {
  padding: 1.38vw;
  border-radius: 0.35vw;
}
.qrBackground {
  width: 18.05vw;
  height: 22.5vw;
  border-radius: 1.8vw;
  outline: 0.11vw solid rgba(211, 0, 0, 0.25);
  background: linear-gradient(
    180deg,
    rgba(211, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  backdrop-filter: blur(0.9vw);
}
.qrContainer {
  display: flex;
  justify-content: center;
  padding: 1.8vw;
}
.seperator {
  border: 0.07vw solid rgba(211, 0, 0, 0.25);
}
.iconContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 0.3vw;
  height: 4vw;
}
.scan {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2.78vw;
  color: #fff;
  font-family: Inter;
  font-size: 1.67vw;
  font-style: normal;
  font-weight: 600;
  line-height: 1.39vw;
}
.qrcircle {
  animation: fadeIn 1s;
  width: 45.14vw;
  height: 45.14vw;
  border-radius: 48.61vw;
  background: #000;
  backdrop-filter: blur(1.04vw);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
