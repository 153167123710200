.getAppButton {
  position: fixed;
  right: 2.5vw;
  top: 8vw;
  display: inline-flex;
  height: 3.33vw;
  padding: 0.69vw 1.67vw;
  justify-content: center;
  gap: 0.69vw;
  flex-shrink: 0;
  border-radius: 2.78vw;
  border: 0.28vw solid #fd5;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #fd5 100%);
  color: #fff;
  font-family: Inter;
  font-size: 1.39vw;
  font-style: normal;
  font-weight: 600;
}
.getAppButton:hover {
  opacity: 0.4;
}

.mainContainer {
  padding-top: 2.08vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5vw;
  scroll-behavior: smooth;
}
.cardsContainer {
  display: flex;
  justify-content: center;
  padding-top: 5vw;
  height: 55vw;
  margin-left: -5vw;
}
.card1 {
  width: 25vw;
  margin-top: -2vw;
  margin-left: -25vw;
  position: absolute;
  transform: translateX(calc(-5.25vw * var(--scroll)))
    translateY(calc(3.446vw * var(--scroll)))
    rotate(calc(-5.747deg * var(--scroll)));
}
.card2 {
  width: 26vw;
  margin-top: -4vw;
  margin-left: 30vw;
  position: absolute;
  transform: translateX(calc(6.25vw * var(--scroll)))
    translateY(calc(3.446vw * var(--scroll)))
    rotate(calc(0.5deg * var(--scroll)));
}
.card3 {
  width: 15vw;
  position: absolute;
  margin-top: 2vw;
  margin-left: 3vw;
  transform: translateY(calc(-3.232vw * 5.5 * var(--scroll)));
}
.card4 {
  margin-left: 2vw;
  margin-top: 1vw;
  width: 21vw;
  position: absolute;
  transform: translateY(calc(10vw * var(--scroll)));
}
@media screen and (max-width: 750px) {
  div.card3 {
    transform: translateY(calc(-3.232vw * 2.5 * var(--scroll)));
  }
}

.gradientText {
  width: 78vw;
  text-align: center;
  font-family: Inter;
  font-size: 8.89vw;
  font-style: normal;
  font-weight: 600;
  line-height: 9.72vw;
  background: linear-gradient(90deg, #833ab4 0%, #fd1d1d 50%, #fcb045 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.typography1 {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 67vw;
  display: flex;
  flex-direction: column;
  gap: 5vw;
  padding-top: 7vw;
}
.flauntText {
  width: 78vw;
  text-align: center;
  font-family: Inter;
  font-size: 8.89vw;
  font-style: normal;
  font-weight: 600;
  line-height: 9.72vw;
  background: linear-gradient(
    90deg,
    #40e0d0 0%,
    #ff8c00 50%,
    rgba(0, 133, 255, 0.8) 69.79%,
    #ff0080 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.stylewstygig {
  margin-top: -4vw;
  background: linear-gradient(
    90deg,
    #b60029 36.16%,
    #9747ff 45.57%,
    rgba(247, 148, 30, 0.8) 59.04%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Inter;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  transition: all 2s ease;
  -webkit-transition: all 2s ease-out;
  -moz-transition: all 2s ease-out;
  -o-transition: all 2s ease-out;
}
.stylewstygig:hover {
  font-size: 2.8vw;
  background: linear-gradient(
    270deg,
    #9747ff 35.92%,
    #b60029 51.6%,
    rgba(247, 148, 30, 0.8) 67.28%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
