.headerSection {
  display: flex;
  gap: 1.39vw;
  align-items: center;
  justify-content: center;
  padding: 2.85vw;
}
.title {
  color: #fff;
  font-family: Inter;
  font-size: 8.89vw;
  font-style: normal;
  font-weight: 600;
  line-height: 9.72vw;
}
.logo {
  width: 8.333vw;
}

@media screen and (max-width: 800px) {
  div.title {
    font-size: 10.89vw;
  }
  div.headerSection{
    padding: 4.85vw;
  }
}
@media screen and (max-width: 450px) {
  div.title {
    font-size: 11.89vw;
  }
  div.headerSection {
    padding:5.85vw;
  }
  div.logo{
    width: 11.333vw;
  }
}

