.screenContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 5vw 0vw;
  height: 42vw;
  width: 45vw;
}

.homeScreen {
  position: absolute;
  width: 19.2vw;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.76vw;
}
.casualTextLarge {
  text-align: center;
  font-family: Inter;
  font-size: 4.44vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: linear-gradient(90deg, #f7941e 0%, #72c6ef 50%, #00a651 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: font-size 0.3s ease, opacity 0.3s ease;
}
.casualText {
  text-align: center;
  font-family: Inter;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.4;
  background: linear-gradient(90deg, #f7941e 0%, #72c6ef 50%, #00a651 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: font-size 0.3s ease, opacity 0.3s ease;
}
.partyText {
  text-align: center;
  font-family: Inter;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.4;
  background: linear-gradient(90deg, #fc00ff 0%, #00dbde 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: font-size 0.3s ease, opacity 0.3s ease;
}
.ethnicText {
  text-align: center;
  font-family: Inter;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.4;
  background: linear-gradient(90deg, #fdfc47 0%, #24fe41 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: font-size 0.3s ease, opacity 0.3s ease;
}
.specialText {
  text-align: center;
  font-family: Inter;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.4;
  background: linear-gradient(90deg, #8a2be2 0%, #ffa500 50%, #f8f8ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: font-size 0.3s ease, opacity 0.3s ease;
}

.partyText:hover,
.casualText:hover,
.specialText:hover,
.ethnicText:hover {
  font-size: 4.44vw;
  opacity: 1;
}
