.tcMain {
  padding: 13.9vw 18.01vw;
}
.tcHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tcHeaderSection {
  display: flex;
  gap: 1.39vw;
  align-items: center;
  justify-content: center;
}
.tcTitle {
  color: #fff;
  font-family: Inter;
  font-size: 3.7vw;
  font-style: normal;
  font-weight: 600;
  line-height: 4.04vw;
}

.tcLogo {
  width: 3.48vw;
  height: 3.7vw;
  display: grid
}

.tcSeperator {
  height: 4vw;
  width: 0vw;
  border: 0.01vw solid #1c1b1f;
}
.tcHeader2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.08vw;
}
.tcGetButton {
  display: inline-flex;
  height: 3.33vw;
  padding: 0.69vw 1.67vw;
  justify-content: center;
  align-items: center;
  gap: 0.69vw;
  flex-shrink: 0;
  border-radius: 2.78vw;
  border: 0.28vw solid #fd5;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #fd5 100%);
  color: #fff;
  font-family: Inter;
  font-size: 1.39vw;
  font-style: normal;
  font-weight: 600;
}
.tcGetButton:hover{
  opacity: 0.4;
}
.tcBody {
  display: flex;
  flex-direction: column;
  gap: 1.4vw;
  padding-top: 4.1vw;
  color: #bdbbbb;
  text-align: justify;
  font-family: Inter;
  font-size: 1.11vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.privacyBody {
  display: flex;
  flex-direction: column;
  gap: 2.4vw;
  padding-top: 4.1vw;
  color: #bdbbbb;
  text-align: justify;
  font-family: Inter;
  font-size: 1.11vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.tcBold {
  /* padding-top: 4.1vw; */

  color: #bdbbbb;
  text-align: justify;
  font-family: Inter;
  font-size: 1.11vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.subtitle {
  color: #fff;
  font-family: Inter;
  font-size: 1.11vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
ul, ol {
  margin: 0;
  padding: 0px 0px 0px 2.78vw;
}
ul li, ol li {
  padding: 0.35vw 0vw;
  color: #bdbbbb;
  text-align: justify;
  font-family: Inter;
  font-size: 1.11vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.tcFooter {
  display: flex;
  padding-top: 6.94vw;
  color: #d9d9d9;
  text-align: center;
  font-family: Inter;
  font-size: 1.11vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
