.imagesContainer {
  display: flex;
  justify-content: center;
  padding-top: 5vw;
  height: 50vw;
  margin-left: -5vw;
}
.imageCard1 {
  width: 35vw;
  margin-left: -10vw;
  transform: translateX(calc(-6.25vw * 2.4 * var(--scroll)))
    translateY(calc(0.446vw * 2.4 * var(--scroll)))
    rotate(calc(16.747deg * var(--scroll)));
}
.imageCard2 {
  width: 28vw;
  margin-top: -2vw;
  margin-left: 3vw;
  position: absolute;
  transform: translateX(calc(-1.79vw * 2.4 * var(--scroll)))
    translateY(calc(-0.893vw * 2.4 * var(--scroll)))
    rotate(calc(10.311deg * var(--scroll)));
}
.imageCard3 {
  width: 20vw;
  position: absolute;
  margin-left: 15vw;
  transform: translateX(calc(4.46vw * 2.4 * var(--scroll)))
    translateY(calc(-2.232vw * 2.4 * var(--scroll)))
    rotate(calc(6.311deg * var(--scroll)));
}
.imageCard4 {
  margin-left: 25vw;
  width: 28vw;
  position: absolute;
  transform: translateX(calc(10.71vw * 2 * var(--scroll)))
    translateY(calc(-1.339vw * 2 * var(--scroll)))
    rotate(calc(5.311deg * var(--scroll)));
}
