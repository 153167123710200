.footerContainer {
  border-top: 1px solid #a58141;
  width: 80vw;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 4.6vw 9.5vw;
}
.footerTypography {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.subContainer {
  display: flex;
  gap: 0.35vw;
  flex-direction: column;
  align-items: baseline;
}
.footerTypography2 {
  padding-top: 0.14vw;
  color: #717171;
  font-family: Inter;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}
.contactContainer {
  display: flex;
  gap: 1.5vw;
  flex-direction: column;
  align-items: center;
}
.contact {
  display: flex;
  text-align: center;
  font-family: Inter;
  font-size: 1.67vw; 
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: linear-gradient(270deg, #FFF 0.15%, #9040E0 53.02%, #FD7B03 108.15%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.iconsContainer {
  display: flex;
  gap: 1.6vw;
  height: 2.5vw;
}
.icons {
  width: 1.6vw;
  display: grid;
}
